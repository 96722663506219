import React, { createContext, useContext, useMemo } from "react";
import LandingPage from "./components/LandingPage";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
// @ts-ignore
import ErrorPage from "./pages/ErrorPage.tsx";
// @ts-ignore
import { AIQPage } from "./pages/AIQ.tsx";
// @ts-ignore
import { Intro } from "./scenarios/Intro.tsx";
// @ts-ignore
import { ADLaunch } from "./scenarios/ads/ADLaunch.tsx";
// @ts-ignore
import { Fun } from "./pages/Fun.tsx";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <div>
        <AIQPage startingScenario={Intro} />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/startQuiz",
    element: (
      <div>
        <AIQPage skipIntro={true} startingScenario={Intro} />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/halloween",
    element: (
      <div>
        <AIQPage startingScenario={ADLaunch} />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
  {
    path: "/bounce",
    element: (
      <div>
        <Fun />
      </div>
    ),
    errorElement: <ErrorPage />,
  },
]);

export const AppToolsContext = React.createContext({
  showHeader: false,
  isPWA: false,
});

function isLocalStorageAvailable() {
  var test = "test";
  try {
    localStorage.setItem(test, test);
    localStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

function App() {
  const localStorageMissing = useMemo(() => !isLocalStorageAvailable(), []);
  if (localStorageMissing) {
    return (
      <div className="flex flex-col items-center justify-center">
        <p className="text-3xl font-bold">Sorry!</p>
        <p className="text-xl text-bold">
          This app requires local storage to work. Please try again on a
          different browser, or update your browser.
        </p>
      </div>
    );
  }
  return (
    <AppToolsContext.Provider
      value={{
        showHeader: false,
        // @ts-ignore
        isPWA: window.navigator.standalone === true,
      }}
    >
      <PWAWrapper />
    </AppToolsContext.Provider>
  );
}

function PWAWrapper() {
  // @ts-ignore
  const iOSIsInstalled = window.navigator.standalone === true;
  if (iOSIsInstalled) {
    return (
      <div id="safe-area" className="pt-12">
        <RouterProvider router={router} />
      </div>
    );
  } else {
    return (
      <div className="">
        {" "}
        <RouterProvider router={router} />
      </div>
    );
  }
}

export default App;
