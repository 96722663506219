import React, { useContext, useEffect } from "react";
// @ts-ignore
import { AIQData } from "../../pages/AIQ.tsx";
// @ts-ignore
import { BorgConversationContext } from "../BorgChat.tsx";

export type AddKeyFn = (key: string, value: any) => void;

export type LongAnswerProps = {
  storageKey: string;
  outputHandler: (addKey: AddKeyFn, result: string) => boolean;
  placeholderText?: string;
};

export function LongAnswer(props: LongAnswerProps) {
  let aiqContext = useContext(AIQData);
  let conversationContext = useContext(BorgConversationContext);

  const [answer, setAnswer] = React.useState("");
  useEffect(() => {
    if (aiqContext.getKey(props.storageKey)) {
      setAnswer(aiqContext.getKey(props.storageKey) ?? "");
    }
  }, [aiqContext, props.storageKey]);

  return (
    <div className="">
      <textarea
        value={answer}
        className="bg-grey-100 border-8 border-black rounded-lg h-[11rem] mt-4 w-full font-bold text-[1rem] p-2 font-mono focus-within:outline-dotted outline-black"
        onChange={(e) => {
          setAnswer(e.target.value);
        }}
      />
      <div className="flex flex-row items-center">
        <button
          className="w-full mt-4 rounded-lg p-2 font-extrabold text-2xl font-mono"
          onClick={() => {
            aiqContext.addKey(props.storageKey, answer);
            let shouldContinue = props.outputHandler(aiqContext.addKey, answer);
            if (shouldContinue) {
              setAnswer("");
              conversationContext.advanceConversation();
            }
          }}
          placeholder={props.placeholderText}
        >
          Submit
        </button>
      </div>
    </div>
  );
}
