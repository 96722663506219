import React, { useContext, useEffect } from "react";
import { useCallback, useState } from "react";
// @ts-ignore
import { BorgEyeContext, BorgEyeState } from "./Eye.tsx";
// @ts-ignore

import { AIQData } from "../pages/AIQ.tsx";

export type PushScenarioFnParams = {
  panes: Array<BorgTextLineProps>;
  onFinishScenario: (push: PushScenarioFn) => void;
};

export type PushScenarioFn = (props: PushScenarioFnParams) => void;

export type BorgConversationReturn = {
  pushScenario: PushScenarioFn;
  chat: JSX.Element;
};

export type BorgConversationContextProps = {
  advanceConversation: () => void;
  transitionScenario: PushScenarioFn;
};
export const BorgConversationContext =
  React.createContext<BorgConversationContextProps>({
    advanceConversation: () => {},
    transitionScenario: () => {},
  });

export function useBorgConversation(): BorgConversationReturn {
  const [scenarios, setScenarios] = useState<PushScenarioFnParams>({
    panes: [],
    onFinishScenario: (push) => {},
  });

  const setScenariosInterceptor = useCallback(
    (pushedScenario: PushScenarioFnParams) => {
      setInternalPaneIndex(0);
      setScenarios(pushedScenario);
    },
    [setScenarios]
  );

  const [internalPaneIndex, setInternalPaneIndex] = useState(0);

  const internalClickHandler = useCallback(() => {
    if (panes && internalPaneIndex < panes.length - 1) {
      // need to skip text loading before this instead of skipping the pane...
      setInternalPaneIndex((i) => i + 1);
    } else {
      scenarios?.onFinishScenario(setScenariosInterceptor);
      setInternalPaneIndex(0);
    }
  }, [setInternalPaneIndex, internalPaneIndex, scenarios]);

  const panes = scenarios?.panes;

  return {
    pushScenario: setScenariosInterceptor,
    chat: (
      <BorgConversationContext.Provider
        value={{
          advanceConversation: internalClickHandler,
          transitionScenario: setScenariosInterceptor,
        }}
      >
        <div
          className="flex flex-col md:items-center"
          onClick={
            panes[internalPaneIndex]?.clickAdvance === false
              ? () => {}
              : internalClickHandler
          }
        >
          <div className="relative bg-black min-h-[30rem] w-auto rounded-xl mx-4 mt-8  md:w-2/3 ">
            <div className="absolute bg-white left-0 top-0 right-0 bottom-0 rounded-xl m-4 px-2  overflow-y-auto">
              {panes[internalPaneIndex] !== undefined ? (
                <BorgTextLine
                  text={panes[internalPaneIndex].text}
                  format={panes[internalPaneIndex].format}
                  eyeState={panes[internalPaneIndex].eyeState}
                  component={panes[internalPaneIndex].component}
                ></BorgTextLine>
              ) : null}
            </div>
          </div>
        </div>
      </BorgConversationContext.Provider>
    ),
  };
}

export type Format = "terminal" | "fullscreen" | "loop";
export type BorgTextLineProps = {
  text: string | ((data: any) => string);
  format?: Format;
  eyeState?: BorgEyeState | string;
  component?: JSX.Element;
  clickAdvance?: boolean;
};

export const BorgTextLine = ({
  text,
  format = "terminal",
  eyeState,
  component,
}: BorgTextLineProps): JSX.Element => {
  let [index, setIndex] = useState({ i: 0, maxi: 0 });
  let userData = useContext(AIQData);

  if (typeof text === "function") {
    text = text(userData.data);
  }
  const shownText = text.slice(0, Math.min(index.i, index.maxi));

  let eyeContext = useContext(BorgEyeContext);
  useEffect(() => {
    if (eyeState) {
      eyeContext.setState(eyeState);
    }
  }, [eyeState]);

  useEffect(() => {
    setIndex({ i: 0, maxi: text.length });
    let interval = setInterval(() => {
      setIndex((index) => {
        if (index.i < index.maxi) {
          return { i: index.i + 2, maxi: index.maxi };
        } else {
          clearInterval(interval);
          return { i: index.i, maxi: index.maxi };
        }
      });
    }, 30);
    return () => {
      clearInterval(interval);
    };
  }, [text]);

  const finished = index.i >= index.maxi;

  switch (format) {
    case "terminal":
      return (
        <div className="p-4">
          <h1 className="text-[1.6rem] font-bold font-mono">
            {shownText}
            <span className=" w-4 h-4 bg-green-200 animate-pulse">
              {finished ? "" : "-"}
            </span>
          </h1>
          {component}
        </div>
      );
    case "fullscreen":
      return (
        <div className="p-4">
          <h1 className="text-[1.6rem] font-bold font-mono">
            {shownText}
            <span className=" w-4 h-4 bg-green-200 animate-pulse">
              {finished ? "" : "-"}
            </span>
          </h1>
          {component}
        </div>
      );
    case "loop":
      return (
        <div className="p-4">
          <h1 className="text-[1.6rem] font-bold font-mono">
            {shownText}
            <span className=" w-4 h-4 bg-green-200 animate-pulse">
              {finished ? "" : "-"}
            </span>
          </h1>
          {component}
        </div>
      );
  }
};
