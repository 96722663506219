// @ts-ignore
import { PushScenarioFn } from "../components/BorgChat.tsx";
// @ts-ignore
import { MultipleChoice } from "../components/card-items/MultipleChoice.tsx";
// @ts-ignore
import { Results } from "../components/card-items/Results.tsx";
// @ts-ignore
import { ResultsLoader } from "../components/card-items/ResultsLoader.tsx";
// @ts-ignore
import { ShortAnswer } from "../components/card-items/ShortAnswer.tsx";
// @ts-ignore
import { FirstQuiz } from "./FirstQuiz.tsx";
// @ts-ignore
import { Persona1 } from "./Persona1.tsx";
// @ts-ignore
import { MatchMakerMaker } from "./matchmaker/MatchMakerMaker.tsx";

import React from "react";

export const ReturningUser = (pushScenario: PushScenarioFn) => {
  console.log("Pushed Scenario");
  pushScenario({
    panes: [
      {
        text: "Would you like to retake the test, or view your old results?",
        eyeState: "bouncing",
        clickAdvance: false,
        component: (
          <MultipleChoice
            storageKey="learn_more_exit"
            choices={{
              yes: "I'm a new person now, let's run it again!",
              no: "Let me see my old results...",
              // debug: "FOR DEBUGGING ONLY",
            }}
            outputHandler={(addKey, result, transitionScenario) => {
              if (result === "yes") {
                Persona1(transitionScenario);
                return false;
              } else if (result === "debug") {
                MatchMakerMaker(transitionScenario);
                return false;
              }
              return true;
            }}
          />
        ),
      },
      {
        text: "",
        eyeState: "bouncing",
        clickAdvance: false,
        component: (
          <Results
            outputHandler={(addKey, result, transitionScenario) => {
              return true;
            }}
            replaceLetterHead={true}
          />
        ),
      },
    ],
    onFinishScenario: (push) => {},
  });
};
