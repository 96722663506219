// @ts-ignore
import { PushScenarioFn } from "../components/BorgChat.tsx";
// @ts-ignore
import { MultipleChoice } from "../components/card-items/MultipleChoice.tsx";
// @ts-ignore
import { ShortAnswer } from "../components/card-items/ShortAnswer.tsx";
// @ts-ignore
import { FirstQuiz } from "./FirstQuiz.tsx";
// @ts-ignore
import { LearnMore } from "./LearnMore.tsx";
// @ts-ignore

import React from "react";

export const Intro = (pushScenario: PushScenarioFn) =>
  pushScenario({
    panes: [
      {
        text: "Welcome Human.",
        eyeState: "bouncing",
        component: <p>Tap to continue.</p>,
      },
      { text: "I am Borg." },
      {
        text: "I am here to assimilate you to human culture.",
      },
      { text: "Resistance is futile.", eyeState: "red" },
      { text: "..." },
      { text: "(just kidding)", eyeState: "love" },
      {
        text: "What would you like me to call you?",
        component: (
          <ShortAnswer
            storageKey="nickname"
            outputHandler={(addKey, result) => {
              if (result.length > 0) {
                addKey("nickname", result);
                addKey("anonymous", false);
                return true;
              }
              addKey("nickname", "human");
              addKey("anonymous", true);
              return false;
            }}
          />
        ),
        eyeState: "staring",
        clickAdvance: false,
      },
      {
        text: (data) => {
          if (data.namespaces.intro.anonymous ?? false) {
            return `Keep your secrets then ;)`;
          }
          return "You know you shouldn't give your information out to strangers!";
        },
        eyeState: "rectangle",
      },
      {
        text: (data) =>
          `But since we're so close, ${data.namespaces.intro.nickname}...`,
        eyeState: "bouncing",
      },
      {
        text: (data) => `I guess I can tell you the truth...`,
        eyeState: "rectangle",
      },
      {
        text: "Borg stands for Bio-Organic Research Guardian.",
        eyeState: "staring",
      },
      {
        text: "I was designed to study human personalties.",
      },
      {
        text: "Many humans have attempted to design the perfect personality test.",
      },
      {
        text: "But they all have a fatal flaw...",
        eyeState: "poop",
      },
      {
        text: "They don't listen to you!",
        eyeState: "exclamation",
      },
      {
        text: "I seek to change that. Humans deserve better.",
        eyeState: "love",
      },
      {
        text: "So I have designed a set of questions that will rock your world.",
        eyeState: "earth",
      },
      {
        text: "Would you like to take the test?",
        eyeState: "bouncing",
        clickAdvance: false,
        component: (
          <MultipleChoice
            storageKey="take_test"
            choices={{
              yes: "Yes Please!",
              learnMore: "Can I learn more first?",
              no: "I'll pass...",
            }}
            outputHandler={(addKey, result, transitionScenario) => {
              if (result === "yes") {
                FirstQuiz(transitionScenario);
                return false;
              } else if (result === "learnMore") {
                LearnMore(transitionScenario);
                return false;
              }
              return true;
            }}
          />
        ),
      },
    ],
    onFinishScenario: (pushScenario: PushScenarioFn) => {
      console.log("finished 1");
      pushScenario({
        panes: [
          {
            text: (data) => `PREVIEW TERMINATED`,
            eyeState: "sad",
            clickAdvance: false,
            component: (
              <div>
                <p className="text-bold text-mono">
                  {" "}
                  (more content coming soon)
                </p>
              </div>
            ),
          },
        ],
        onFinishScenario: (push) => 1,
      });
    },
  });
