import React, {
  createRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
// @ts-ignore
import { BorgEyeContext, BorgEyeState, Eye } from "../components/Eye.tsx";
// @ts-ignore
import {
  PushScenarioFn,
  useBorgConversation,
  // @ts-ignore
} from "../components/BorgChat.tsx";
// @ts-ignore
import { Intro } from "../scenarios/Intro.tsx";
// @ts-ignore
import { LearnMore } from "../scenarios/LearnMore.tsx";
// @ts-ignore
import { FirstQuiz } from "../scenarios/FirstQuiz.tsx";
// @ts-ignore
import { Persona1 } from "../scenarios/Persona1.tsx";
// @ts-ignore
import { ADLaunch } from "../scenarios/ads/ADLaunch.tsx";
// @ts-ignore
import { ReturningUser } from "../scenarios/ReturningUser.tsx";

type QuestionPack = {
  pack_name: string;
  pack_description: string;
  prompt_insert: string;
  questions: Array<string>;
};

type AIQData = {
  namespaces: {
    intro: {
      name?: string;
      nickname?: string;
      results?: string;
      paid: boolean;
    };
  };
};
export type AIQDataProps = {
  data: AIQData;
  currentNamespace: string;
  addKey: (key: string, value: any) => void;
  getKey: (key: string) => string | null;
};

export const AIQData = React.createContext<AIQDataProps>({
  data: {
    namespaces: {
      intro: {
        paid: false,
      },
    },
  },
  currentNamespace: "",
  addKey: (key: string, value: any) => {},
  getKey: (key: string) => {
    return "";
  },
});

type AIQPageProps = {
  skipIntro?: boolean;
  startingScenario: (pushScenario: PushScenarioFn) => void;
};

export function AIQPage({ skipIntro, startingScenario }: AIQPageProps): any {
  const themeColorMeta = document.querySelector('meta[name="theme-color"]');
  useMemo(() => {
    if (themeColorMeta) {
      themeColorMeta.setAttribute("content", "#823456");
    }
  }, [themeColorMeta]);

  const { pushScenario, chat } = useBorgConversation();

  // Storage for Quiz Reloading Safety Net
  let userDataStorage = useMemo(() => {
    let rawStorageData = localStorage.getItem("aiq_data");
    if (rawStorageData !== null) {
      return JSON.parse(rawStorageData ?? "{}");
    } else {
      return {
        namespaces: {
          intro: {},
        },
      };
    }
  }, []);

  // Initialize the scenarios for conversation
  useMemo(() => {
    if (userDataStorage.namespaces.intro["results"] !== undefined) {
      console.log(userDataStorage.namespaces.intro["results"]);

      pushScenario({
        panes: [
          {
            text: `Welcome back ${
              userDataStorage.namespaces.intro["nickname"] ?? "Human"
            }!`,
            eyeState: "love",
          },
        ],
        onFinishScenario: (push) => {
          ReturningUser(pushScenario);
        },
      });
      return;
    }
    if (skipIntro === true) {
      // this is for the girlies who paid
      pushScenario({
        panes: [
          {
            text: "Thank you so much!!! Borg will work very hard to analyze your results!",
            eyeState: "super-love",
          },
        ],
        onFinishScenario: (push) => {
          Persona1(pushScenario);
        },
      });
    } else {
      startingScenario(pushScenario);
    }
  }, []);

  const [userData, setUserData] = useState<any>(userDataStorage);
  const [currentNamespace, setCurrentNamespace] = useState("intro");

  const addKey = useCallback((key: string, value: any) => {
    setUserData((data) => {
      // TODO: Add a check to make sure the key doesn't already exist
      data.namespaces[currentNamespace][key] = value;
      localStorage.setItem("aiq_data", JSON.stringify(data));
      return data;
    });
  }, []);

  const getKey = useCallback(
    (key: string) => {
      try {
        return userData.namespaces[currentNamespace][key];
      } catch (e) {
        return null;
      }
    },
    [userData, currentNamespace]
  );

  const [eyeState, setEyeState] = useState<BorgEyeState>("staring");
  console.log(userData);

  return (
    <AIQData.Provider
      value={{
        data: userData,
        currentNamespace: "intro",
        addKey,
        getKey,
      }}
    >
      <BorgEyeContext.Provider
        value={{ state: eyeState, setState: setEyeState }}
      >
        <div className="h-[3vh] bg-[#823456] w-[100vw]"></div>
        <div className="h-[2vh] bg-black w-[100vw]"></div>
        <div className="h-[100vh] bg-blue-100 w-[100vw] overflow-auto">
          <div id="aiq-col-container" className="flex flex-col items-center">
            <Eye onClick={() => {}}></Eye>
          </div>
          {chat}
        </div>
      </BorgEyeContext.Provider>
    </AIQData.Provider>
  );
}
