import React, {
  createRef,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
// @ts-ignore

export function Fun(): any {
  return <canvas className="bg-black w-[100vw] h-[100vh] flex "></canvas>;
}
