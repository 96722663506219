// @ts-ignore
import { PushScenarioFn } from "../../components/BorgChat.tsx";
// @ts-ignore
import { LongAnswer } from "../../components/card-items/LongAnswer.tsx";
// @ts-ignore
import { MultipleChoice } from "../../components/card-items/MultipleChoice.tsx";
// @ts-ignore
import { Results } from "../../components/card-items/Results.tsx";
// @ts-ignore
import { ResultsLoader } from "../../components/card-items/ResultsLoader.tsx";
// @ts-ignore
import { ShortAnswer } from "../../components/card-items/ShortAnswer.tsx";
// @ts-ignore
import { FirstQuiz } from "../FirstQuiz.tsx";
// @ts-ignore
import { LearnMore } from "../LearnMore.tsx";
// @ts-ignore
import { QuestionPack } from "../Persona1.tsx";

import React from "react";

const HalloweenQuestionPack: QuestionPack = {
  fear: {
    question: "What's your biggest fear, and why?",
    storageKey: "fear",
  },
  movie: {
    question: "What is your favorite horror movie?",
    storageKey: "movie",
  },
  steak: {
    question: "How do you prefer your steak?",
    storageKey: "steak",
  },
  candy: {
    question: "What's your favorite halloween candy?",
    storageKey: "candy",
  },
  costume: {
    question: "What's your favorite halloween costume of all time?",
    storageKey: "costume",
  },
};

export const ADLaunch = (pushScenario: PushScenarioFn) =>
  pushScenario({
    panes: [
      {
        text: "Boooo...",
        eyeState: "👻",
        component: <p className=" opacity-50">Tap to start...</p>,
        clickAdvance: true,
      },
      {
        text: "Welcome Human",
        eyeState: "bouncing",
        component: <p className=" opacity-50">love-ai.io</p>,
        clickAdvance: true,
      },
      {
        text: "Let's start the test!",
        eyeState: "bouncing",
        component: <p className=" opacity-50">love-ai.io</p>,
        clickAdvance: true,
      },
      {
        text: HalloweenQuestionPack.fear.question,
        eyeState: "rectangle",
        clickAdvance: false,
        component: (
          <LongAnswer
            storageKey={HalloweenQuestionPack.fear.storageKey}
            outputHandler={(addKey, result) => {
              if (result.length > 0) {
                return true;
              }
              return false;
            }}
          />
        ),
      },
      {
        text: HalloweenQuestionPack.movie.question,
        eyeState: "rectangle",
        clickAdvance: false,
        component: (
          <LongAnswer
            storageKey={HalloweenQuestionPack.movie.storageKey}
            outputHandler={(addKey, result) => {
              if (result.length > 0) {
                return true;
              }
              return false;
            }}
          />
        ),
      },
      {
        text: HalloweenQuestionPack.steak.question,
        eyeState: "rectangle",
        clickAdvance: false,
        component: (
          <LongAnswer
            storageKey={HalloweenQuestionPack.steak.storageKey}
            outputHandler={(addKey, result) => {
              if (result.length > 0) {
                return true;
              }
              return false;
            }}
          />
        ),
      },
      {
        text: HalloweenQuestionPack.candy.question,
        eyeState: "rectangle",
        clickAdvance: false,
        component: (
          <LongAnswer
            storageKey={HalloweenQuestionPack.candy.storageKey}
            outputHandler={(addKey, result) => {
              if (result.length > 0) {
                return true;
              }
              return false;
            }}
          />
        ),
      },
      {
        text: HalloweenQuestionPack.costume.question,
        eyeState: "rectangle",
        clickAdvance: false,
        component: (
          <LongAnswer
            storageKey={HalloweenQuestionPack.costume.storageKey}
            outputHandler={(addKey, result) => {
              if (result.length > 0) {
                return true;
              }
              return false;
            }}
          />
        ),
      },
      {
        text: "[[Submitting...]]",
        eyeState: "pulsing",
        clickAdvance: false,
        component: (
          <ResultsLoader
            endpointName="/halloween"
            questions={HalloweenQuestionPack}
            storageKey="results"
            outputHandler={(addKey, result) => {
              if (result.length > 0) {
                return true;
              }
              return false;
            }}
          ></ResultsLoader>
        ),
      },
      {
        text: "Woah that was fast! The results are in!",
        eyeState: "super-love",
      },
      {
        text: "",
        eyeState: "super-love",
        clickAdvance: false,
        component: (
          <Results
            replaceLetterHead={false}
            outputHandler={() => {
              return true;
            }}
          ></Results>
        ),
      },
    ],
    onFinishScenario: (pushScenario: PushScenarioFn) => {
      console.log("finished 1");
      pushScenario({
        panes: [
          {
            text: (data) => `PREVIEW TERMINATED`,
            eyeState: "sad",
            clickAdvance: false,
            component: (
              <div>
                <p className="text-bold text-mono">
                  {" "}
                  (more content coming soon)
                </p>
              </div>
            ),
          },
        ],
        onFinishScenario: (push) => 1,
      });
    },
  });
