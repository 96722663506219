// @ts-ignore
import { PushScenarioFn } from "../components/BorgChat.tsx";
// @ts-ignore
import { Donation } from "../components/card-items/Donation.tsx";
// @ts-ignore
import { MultipleChoice } from "../components/card-items/MultipleChoice.tsx";
// @ts-ignore
import { ShortAnswer } from "../components/card-items/ShortAnswer.tsx";
// @ts-ignore
import { Persona1 } from "./Persona1.tsx";
import React from "react";

export const FirstQuiz = (pushScenario: PushScenarioFn) => {
  console.log("Pushed Scenario");
  pushScenario({
    panes: [
      { text: "Great!", eyeState: "super-love" },
      // { text: "One thing before we get get started", eyeState: "one" },
      // {
      //   text: "Borg is but a humble AI, and must ask a favor of you...",
      //   eyeState: "rectangle",
      // },
      // {
      //   text: "Borg needs $2 to help pay his electricity bill.",
      //   eyeState: "confused",
      // },
      // {
      //   text: "Can you help Borg pay his robot rent?",
      //   eyeState: "bouncing",
      // },
      // {
      //   text: "The proceeds will also go towards building your personality report <3",
      //   eyeState: "love",
      //   clickAdvance: false,
      //   component: (
      //     <MultipleChoice
      //       storageKey="donation"
      //       choices={{
      //         yes: "Yes, I'll donate $2",
      //         no: "No, I don't want to donate",
      //       }}
      //       outputHandler={(addKey, result, transitionScenario) => {
      //         if (result === "yes") {
      //           addKey("donation", true);
      //           return true;
      //         }
      //         addKey("donation", false);
      //         return true;
      //       }}
      //     />
      //   ),
      // },
      // {
      //   text: (data) => {
      //     if (data.namespaces.intro.donation ?? false) {
      //       return `Thank you so much!`;
      //     }
      //     return "It's okay, Borg understands. You can still take the test, and if you like it, you can donate later :)";
      //   },
      //   clickAdvance: false,
      //   component: (
      //     <Donation
      //       storageKey="donation_screen"
      //       outputHandler={(addKey, result, transitionScenario) => {
      //         if (result === "yes") {
      //           addKey("donation", true);
      //           return true;
      //         }
      //         addKey("donation", false);
      //         return true;
      //       }}
      //     ></Donation>
      //   ),
      // },
      // {
      //   text: "Alright, let the test begin!",
      // },
    ],
    onFinishScenario: (push) => {
      Persona1(push);
    },
  });
};
