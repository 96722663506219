// @ts-ignore
import { PushScenarioFn } from "../components/BorgChat.tsx";
// @ts-ignore
import { LongAnswer } from "../components/card-items/LongAnswer.tsx";
// @ts-ignore
import { MultipleChoice } from "../components/card-items/MultipleChoice.tsx";
// @ts-ignore
import { Results } from "../components/card-items/Results.tsx";
// @ts-ignore
import { ResultsLoader } from "../components/card-items/ResultsLoader.tsx";
// @ts-ignore
import { ShortAnswer } from "../components/card-items/ShortAnswer.tsx";
import React from "react";

export type QuestionPack = Record<
  string,
  { question: string; storageKey: string }
>;

const Persona1QuestionPack: QuestionPack = {
  home: {
    question: "What makes you happy?",
    storageKey: "home",
  },
  favorite_person: {
    question: "Who is your favorite person and why?",
    storageKey: "favorite_person",
  },
  proud_of: {
    question: "What is something you are proud of?",
    storageKey: "proud_of",
  },
  biggest_dream: {
    question: "What is your biggest dream?",
    storageKey: "biggest_dream",
  },
  biggest_friend_trait: {
    question: "What is the biggest thing you look for in a friend, and why?",
    storageKey: "biggest_friend_trait",
  },
  ideal_partner_personality: {
    question: "Describe the personality of your ideal partner.",
    storageKey: "ideal_partner_personality",
  },
  self_description: {
    question: "Describe your personality.",
    storageKey: "self_description",
  },
  love_definition: {
    question: "When do you feel most loved?",
    storageKey: "love_definition",
  },
};

export const Persona1 = (pushScenario: PushScenarioFn) => {
  pushScenario({
    panes: [
      {
        text: "I want you to get the most out of this as possible.",
        eyeState: "bouncing",
      },
      {
        text: "These questions are designed to understand your perspective on big topics such as life, love, and happiness.",
        eyeState: "bouncing",
      },
      {
        text: "There are no right or wrong answers, just be honest with yourself.",
        eyeState: "bouncing",
      },
      {
        text: "Don't be afraid to dig deep and think about your answers.",
        eyeState: "bouncing",
      },
      {
        text: "The longer your answers, the better the result!",
        eyeState: "love",
      },
      {
        text: "Alright, enough talk, let's get started! There are 8 long answer questions total.",
        eyeState: "8",
      },
      {
        text: "Warm Up: " + Persona1QuestionPack.home.question,
        eyeState: "bouncing",
        clickAdvance: false,
        component: (
          <LongAnswer
            storageKey={Persona1QuestionPack.home.storageKey}
            outputHandler={(addKey, result) => {
              if (result.length > 0) {
                return true;
              }
              return false;
            }}
          />
        ),
      },
      {
        text: Persona1QuestionPack.favorite_person.question,
        clickAdvance: false,
        component: (
          <LongAnswer
            storageKey={Persona1QuestionPack.favorite_person.storageKey}
            outputHandler={(addKey, result) => {
              if (result.length > 0) {
                return true;
              }
              return false;
            }}
          />
        ),
      },
      {
        text: Persona1QuestionPack.proud_of.question,
        clickAdvance: false,
        component: (
          <LongAnswer
            storageKey={Persona1QuestionPack.proud_of.storageKey}
            outputHandler={(addKey, result) => {
              if (result.length > 0) {
                return true;
              }
              return false;
            }}
          />
        ),
      },
      {
        text: Persona1QuestionPack.biggest_dream.question,
        eyeState: "rectangle",
        clickAdvance: false,
        component: (
          <LongAnswer
            storageKey={Persona1QuestionPack.biggest_dream.storageKey}
            outputHandler={(addKey, result) => {
              if (result.length > 0) {
                return true;
              }
              return false;
            }}
          />
        ),
      },
      {
        text: Persona1QuestionPack.biggest_friend_trait.question,
        eyeState: "rectangle",
        clickAdvance: false,
        component: (
          <LongAnswer
            storageKey={Persona1QuestionPack.biggest_friend_trait.storageKey}
            outputHandler={(addKey, result) => {
              if (result.length > 0) {
                return true;
              }
              return false;
            }}
          />
        ),
      },
      {
        text: Persona1QuestionPack.ideal_partner_personality.question,
        eyeState: "rectangle",
        clickAdvance: false,
        component: (
          <LongAnswer
            storageKey={
              Persona1QuestionPack.ideal_partner_personality.storageKey
            }
            outputHandler={(addKey, result) => {
              if (result.length > 0) {
                return true;
              }
              return false;
            }}
          />
        ),
      },
      {
        text: Persona1QuestionPack.self_description.question,
        eyeState: "rectangle",
        clickAdvance: false,
        component: (
          <LongAnswer
            storageKey={Persona1QuestionPack.self_description.storageKey}
            outputHandler={(addKey, result) => {
              if (result.length > 0) {
                return true;
              }
              return false;
            }}
          />
        ),
      },
      {
        text:
          "Final Question: " + Persona1QuestionPack.love_definition.question,
        eyeState: "rectangle",
        clickAdvance: false,
        component: (
          <LongAnswer
            storageKey="love_definition"
            outputHandler={(addKey, result) => {
              if (result.length > 0) {
                return true;
              }
              return false;
            }}
          />
        ),
      },
      {
        text: "Holy shit, you made it through!",
        eyeState: "super-love",
      },
      {
        text: "Let me prepare your answers for submission...",
        eyeState: "bouncing",
      },
      {
        text: "[[Submitting...]]",
        eyeState: "pulsing",
        clickAdvance: false,
        component: (
          <ResultsLoader
            endpointName="/helloworld"
            questions={Persona1QuestionPack}
            storageKey="results"
            outputHandler={(addKey, result) => {
              if (result.length > 0) {
                return true;
              }
              return false;
            }}
          ></ResultsLoader>
        ),
      },
      {
        text: "Woah that was fast! The results are in!",
        eyeState: "super-love",
      },
      {
        text: "",
        eyeState: "super-love",
        clickAdvance: false,
        component: (
          <Results
            replaceLetterHead={true}
            outputHandler={() => {
              return true;
            }}
          ></Results>
        ),
      },
    ],
    onFinishScenario: (push) => {},
  });
};
