import React, { useEffect, useRef } from "react";

export const BorgEyeContext = React.createContext<{
  state: BorgEyeState;
  setState: (BorgEyeState) => void;
}>({
  state: "bouncing",
  setState: (x: BorgEyeState) => {},
});

export type EyeProps = {
  onClick: () => void;
};

export type BorgEyeState =
  | "bouncing"
  | "pulsing"
  | "staring"
  | "rectangle"
  | "confused"
  | "super-love"
  | "love"
  | "red"
  | "poop"
  | "star"
  | "super-star"
  | "exclamation"
  | "earth"
  | "sad"
  | "fire"
  | "two"
  | "one"
  | "eight";

const Pupil = (props: { state: BorgEyeState | string }) => {
  switch (props.state) {
    case "pulsing":
      return (
        <div className="relative w-[3rem] h-[3rem] rounded-full bg-black mt-[5rem] animate-bounce">
          <div className="absolute ml-[1rem] w-[1rem] h-[3rem] bg-black mt-3 justify-center flex "></div>
          <div className="absolute w-[3rem] h-[3rem] rounded-full bg-black animate-ping justify-center flex ">
            <div className="w-[2rem] h-[2rem] mt-1 rounded-full bg-black animate-ping justify-center flex ">
              <div className="w-[1rem] h-[1rem] rounded-full bg-black animate-ping mt-8"></div>
            </div>
          </div>
        </div>
      );
    case "bouncing":
      return (
        <div className="relative w-[3rem] h-[3rem] rounded-full bg-black mt-[7rem] animate-bounce"></div>
      );
    case "rectangle":
      return (
        <div className="relative w-[4rem] h-[1rem] rounded-[0.4rem] bg-black mt-[3rem]"></div>
      );
    case "confused":
      return (
        <div className="relative w-[3.5rem] h-[1rem] rounded-[0.4rem] bg-black mt-[3rem] animate-spin"></div>
      );
    case "super-love":
      return (
        <div className="relative mt-[4rem] text-[3rem] z-[10] saturate-[0.6] brightness-0 animate-pulse">
          <div className="absolute animate-ping z-[-1] -mt-1">❤️</div>❤️
        </div>
      );
    case "love":
      return (
        <div className="relative mt-[4rem] text-[3rem] z-[10] saturate-[0.6] brightness-0 animate-pulse">
          ❤️
        </div>
      );
    case "red":
      return (
        <div className="relative w-[3rem] h-[3rem] rounded-full bg-red-400 mt-[2rem] animate-pulse"></div>
      );
    case "super-star":
      return (
        <div className="relative mt-[2rem] text-[3.2rem] z-[10] saturate-[0] brightness-[0]">
          <div className="absolute animate-ping z-[-1] -mt-0">🌟</div>🌟
        </div>
      );
    case "star":
      return (
        <div className="relative mt-[2rem] text-[3.2rem] z-[10] saturate-[0.6] brightness-0">
          🌟
        </div>
      );
    case "poop":
      return (
        <div className="relative mt-[2rem] text-[3.2rem] z-[10] saturate-[0.6] brightness-0">
          💩
        </div>
      );
    case "exclamation":
      return (
        <div className="relative mt-[3rem] text-[3.2rem] z-[10] animate-ping">
          ❗
        </div>
      );
    case "earth":
      return (
        <div className="relative mt-[6rem] text-[3.2rem] z-[10] saturate-[1] animate-spin">
          🌎
        </div>
      );
    case "sad":
      return (
        <div className="relative mt-[4rem] text-[3.2rem] z-[10] saturate-[1]">
          😢
        </div>
      );
    case "fire":
      return (
        <div className="relative mt-[6rem] text-[3.2rem] z-[10] saturate-[1] animate-pulse">
          🔥
        </div>
      );
    case "two":
      return (
        <div className="relative mt-[3rem] text-[3.2rem] z-[10] saturate-[1] animate-pulse font-extrabold">
          2
        </div>
      );
    case "one":
      return (
        <div className="relative mt-[3rem] text-[3.2rem] z-[10] saturate-[1] animate-pulse font-extrabold">
          1
        </div>
      );
    case "eight":
      return (
        <div className="text-mono relative mt-[3rem] text-[4.2rem] z-[10] saturate-[1] animate-pulse font-extrabold">
          8
        </div>
      );
    case "staring":
      return (
        <div className="relative w-[3rem] h-[3rem] rounded-full bg-black mt-[4rem] animate-pulse"></div>
      );
    default:
      return (
        <div className="text-mono relative mt-[3rem] text-[4.2rem] z-[10] saturate-[1] animate-pulse font-extrabold">
          {props.state}
        </div>
      );
  }
};

export const Eye = ({ onClick }: EyeProps) => {
  let context = React.useContext(BorgEyeContext);

  return (
    <div
      id="eye-root"
      className="-mt-14 w-[10rem] h-[10rem] rounded-full bg-black items-center justify-center flex"
    >
      <div
        className="relative w-[8rem] h-[8rem] rounded-full bg-white overflow-hidden justify-center flex items-center border-white border-[0.2rem] lg:border-none"
        onClick={onClick}
      >
        <Pupil state={context.state}></Pupil>
      </div>
    </div>
  );
};

const EmojiFall = () => {
  let canvas = useRef<null | HTMLCanvasElement>(null);
  useEffect(() => {
    if (canvas.current) {
      const ctx = canvas.current.getContext("2d");
      if (ctx) {
        ctx.canvas.width = window.innerWidth;
        ctx.canvas.height = window.innerHeight;
        ctx.fillStyle = "red";
        // ctx.fillRect(0, 0, 100, 100);
        ctx.font = "30px Arial";
        let m = 10;
        let i = setInterval(() => {
          ctx.clearRect(0, 0, window.innerWidth, window.innerHeight);
          ctx.fillText("❤️", m, 50);
          m += 2;
        }, 100);
        setTimeout(() => {
          clearInterval(i);
        }, 10000);
      }
    }
  }, [canvas]);
  return (
    <canvas
      id="emoji-fall-canvas"
      className=" w-[100vw] h-[100vh]"
      ref={canvas}
    ></canvas>
  );
};
