// @ts-ignore
import { PushScenarioFn } from "../../components/BorgChat.tsx";
// @ts-ignore
import { LongAnswer } from "../../components/card-items/LongAnswer.tsx";
// @ts-ignore
import { MultipleChoice } from "../../components/card-items/MultipleChoice.tsx";
// @ts-ignore
import { PrepPayment } from "../../components/card-items/PrepPayment.tsx";
// @ts-ignore
import { Results } from "../../components/card-items/Results.tsx";
// @ts-ignore
import { ResultsLoader } from "../../components/card-items/ResultsLoader.tsx";
// @ts-ignore
import { ShortAnswer } from "../../components/card-items/ShortAnswer.tsx";
import React from "react";

export type QuestionPack = Record<
  string,
  { question: string; storageKey: string }
>;

export const MatchMakerMaker = (pushScenario: PushScenarioFn) => {
  pushScenario({
    panes: [
      {
        text: "Yay! I'm glad to help out!",
        eyeState: "super-love",
      },
      {
        text: "First, we need to set up your secret message!",
        eyeState: "bouncing",
        component: (
          <div>
            <p className=" font-bold">
              Here are some strategies you may want to use:
            </p>
            <p className="ml-2">
              <span className=" font-bold">-</span> Use a secret passcode so you
              know your match has passed your test
            </p>
            <p className="ml-2">
              <span className=" font-bold">-</span> Pass along your phone number
              to matches, and make them earn it
            </p>
            <p className="ml-2">
              <span className=" font-bold">-</span> Share your favorite social
              media handle, like insta
            </p>
            <p className="ml-2">
              <span className=" font-bold">-</span> Make it a riddle, and stay
              alone forever
            </p>
          </div>
        ),
      },
      {
        text: "What would you like to say to your matches?",
        eyeState: "bouncing",
        clickAdvance: false,
        component: (
          <LongAnswer
            storageKey="secret_message"
            outputHandler={(addKey, result) => {
              if (result.length > 0) {
                addKey("secret_message", result);
                return true;
              }
              return false;
            }}
          />
        ),
      },
      {
        text: "Now, let's decide what questions to ask!",
        eyeState: "bouncing",
      },
      {
        text: "I can make you a quiz with our powerful matchmaking AI for a small fee, or you can make your own!",
        eyeState: "love",
        clickAdvance: false,
        component: (
          <MultipleChoice
            storageKey="quiz_creation_selector"
            choices={{
              ai: "Make me a unique quiz, please!",
              free: "I'll design my own questions...",
            }}
            outputHandler={(addKey, result, transitionScenario) => {
              if (result === "ai") {
                return true;
              } else {
                // TODO: Link to empty quiz maker
                return false;
              }
            }}
          />
        ),
      },
      {
        text: "Will do boss <3",
        eyeState: "love",
      },
      {
        text: "How many questions would you like?",
        eyeState: "bouncing",
        clickAdvance: false,
        component: (
          <MultipleChoice
            storageKey="quiz_creation_selector"
            choices={{
              q10: "Generate 10 questions ($2 USD)",
              q20: "Generate 20 questions ($4 USD)",
              nevermind: "Actually, I'll make my own...",
              debug: "Tester (free)",
            }}
            outputHandler={(addKey, result, transitionScenario) => {
              // TODO: Link to empty quiz maker, or promo code input
              return true;
            }}
          />
        ),
      },
      {
        text: "Let me get things ready...",
        eyeState: "pulsing",
        component: <PrepPayment />,
        clickAdvance: false,
      },
      {
        text: "Sounds",
        eyeState: "bouncing",
        clickAdvance: false,
        component: (
          <MultipleChoice
            storageKey="quiz_creation_selector"
            choices={{
              q10: "Generate 10 questions ($3 USD)",
              q15: "Generate 15 questions ($4 USD)",
              q20: "Generate 20 questions ($5 USD)",
              promo: "I have a promo code!",
            }}
            outputHandler={(addKey, result, transitionScenario) => {
              return true;
            }}
          />
        ),
      },
      {
        text: "You can use the message to pass along your phone number, a link to your prefered social media, a secret flirting password, or anything else you can think of!",
        eyeState: "super-love",
      },
    ],
    onFinishScenario: (push) => {},
  });
};
