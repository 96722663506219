import React, { useCallback, useContext } from "react";
// @ts-ignore
import { AIQData } from "../../pages/AIQ.tsx";
// @ts-ignore
import { BorgConversationContext } from "../BorgChat.tsx";

export type AddKeyFn = (key: string, value: any) => void;

export type PrepPaymentProps = {};

export function PrepPayment(props: PrepPaymentProps) {
  let aiqContext = useContext(AIQData);
  // TODO: if local storage is not available this will fail
  const serializedData = JSON.stringify(aiqContext.data);
  let uri = new URL("https://square.link/u/YQBbaKWf");
  uri.searchParams.append("quiz_data", btoa(serializedData));
  // Lifecycle:
  // 1. Store data in KV

  return <a href={uri.toString()}>Test Payment</a>;
}

// A react component that takes in a question pack and returns a quiz

type QuestionPack = Array<{
  question: string;
  title: string;
  emoji: string;
}>;
