// @ts-ignore
import { PushScenarioFn } from "../components/BorgChat.tsx";
// @ts-ignore
import { MultipleChoice } from "../components/card-items/MultipleChoice.tsx";
// @ts-ignore
import { ShortAnswer } from "../components/card-items/ShortAnswer.tsx";
// @ts-ignore
import { FirstQuiz } from "./FirstQuiz.tsx";

import React from "react";

export const LearnMore = (pushScenario: PushScenarioFn) => {
  console.log("Pushed Scenario");
  pushScenario({
    panes: [
      { text: "A cautious one, I love it :)", eyeState: "super-love" },
      { text: "Let me explain a bit more about Borg.", eyeState: "bouncing" },
      {
        text: "I was designed and built by Tuck. An engineer and artist.",
        eyeState: "fire",
      },
      {
        text: "He was inspired by how dificult it was to find friends and dates in an online world.",
        eyeState: "bouncing",
      },
      {
        text: "Dating apps like Tinder are designed to keep you swiping...",
        eyeState: "rectangle",
      },
      {
        text: "And even worse, they don't showcase anyone's personality, or values.",
        eyeState: "confused",
      },
      {
        text: "Borg's mission is to help others understand themselves better...",
        eyeState: "love",
      },
      {
        text: "...and empower them to find deeper connections through technology.",
        eyeState: "super-love",
      },
      {
        text: "At the end of this test you will recieve a fully personalized report into your personality.",
        eyeState: "bouncing",
      },
      {
        text: "How does all of that sound?",
        eyeState: "bouncing",
        clickAdvance: false,
        component: (
          <MultipleChoice
            storageKey="learn_more_exit"
            choices={{
              yes: "Sounds like fun! Let's do it!",
              no: "I'll pass...",
            }}
            outputHandler={(addKey, result, transitionScenario) => {
              if (result === "yes") {
                FirstQuiz(transitionScenario);
                return false;
              }
              return true;
            }}
          />
        ),
      },
    ],
    onFinishScenario: (push) => {
      pushScenario({
        panes: [
          {
            text: (data) =>
              `Awe, Okay. Borg understands. Come back if you change your mind!`,
            eyeState: "sad",
            clickAdvance: false,
            component: (
              <div>
                <p className="text-bold text-mono"> (Bye Bye, friend!)</p>
              </div>
            ),
          },
        ],
        onFinishScenario: (push) => 1,
      });
    },
  });
};
