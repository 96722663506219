import React, { useCallback, useContext } from "react";
// @ts-ignore
import { AIQData } from "../../pages/AIQ.tsx";
// @ts-ignore
import { BorgConversationContext } from "../BorgChat.tsx";

export type AddKeyFn = (key: string, value: any) => void;

export type MultipleChoiceProps = {
  storageKey: string;
  outputHandler: (
    addKey: AddKeyFn,
    result: string,
    transitionScenario
  ) => boolean;
  choices: Record<string, string>;
};

export function MultipleChoice(props: MultipleChoiceProps) {
  let aiqContext = useContext(AIQData);
  let conversationContext = useContext(BorgConversationContext);

  let onSubmit = useCallback(
    (answer: string) => {
      aiqContext.addKey(props.storageKey, answer);
      let result = props.outputHandler(
        aiqContext.addKey,
        answer,
        conversationContext.transitionScenario
      );
      if (!result) {
        return;
      }
      conversationContext.advanceConversation();
    },
    [aiqContext, props, conversationContext]
  );

  const [activeKey, setActiveKey] = React.useState("NULL");
  return (
    <div className="">
      <div className="flex flex-col items-center">
        {Object.entries(props.choices).map(([key, value], i) =>
          activeKey === key ? (
            <div
              key={key}
              onClick={() => {
                setActiveKey(key);
              }}
              className="flex flex-col bg-green-500 w-full text-white font-bold font-mono text-left p-2 pl-2 mt-2 rounded-lg"
            >
              {value}
            </div>
          ) : (
            <div
              key={key}
              onClick={() => {
                setActiveKey(key);
              }}
              className="flex flex-col bg-black w-full text-white font-mono text-left p-2 pl-2 mt-2 rounded-lg"
            >
              {value}
            </div>
          )
        )}
        {activeKey === "NULL" || (
          <button
            className="w-full mt-4 rounded-lg p-2 font-extrabold text-2xl font-mono hover:bg-gray-200 select-none"
            onClick={() => {
              onSubmit(activeKey);
            }}
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );
}
