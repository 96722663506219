import React, { useContext } from "react";
// @ts-ignore
import { AIQData } from "../../pages/AIQ.tsx";
// @ts-ignore
import { BorgConversationContext } from "../BorgChat.tsx";

export type AddKeyFn = (key: string, value: any) => void;

export type ShortAnswerProps = {
  storageKey: string;
  outputHandler: (addKey: AddKeyFn, result: string) => boolean;
  placeholderText?: string;
};

export function ShortAnswer(props: ShortAnswerProps) {
  let aiqContext = useContext(AIQData);
  let conversationContext = useContext(BorgConversationContext);

  const [answer, setAnswer] = React.useState("");
  return (
    <div className="">
      <input
        type="text"
        className="border-8 border-black rounded-lg h-16 mt-4 w-full font-bold text-[1.5rem] p-2 font-mono focus-within:outline-dotted outline-black"
        onChange={(e) => {
          setAnswer(e.target.value.trim());
        }}
      />
      <div className="flex flex-row items-center">
        <button
          className="w-full mt-4 rounded-lg p-2 font-extrabold text-2xl font-mono"
          onClick={() => {
            aiqContext.addKey(props.storageKey, answer);
            props.outputHandler(aiqContext.addKey, answer);
            conversationContext.advanceConversation();
          }}
          placeholder={props.placeholderText}
        >
          Submit
        </button>
      </div>
    </div>
  );
}
