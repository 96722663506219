import React, { useCallback, useContext, useMemo } from "react";
// @ts-ignore
import { AIQData } from "../../pages/AIQ.tsx";
// @ts-ignore
import { BorgConversationContext } from "../BorgChat.tsx";
// @ts-ignore
import { MatchMakerIntro } from "../../scenarios/matchmaker/MatchMakerIntro.tsx";

export type AddKeyFn = (key: string, value: any) => void;

export type ResultsProps = {
  outputHandler: (
    addKey: AddKeyFn,
    result: string,
    transitionScenario
  ) => boolean;
  replaceLetterHead: boolean;
};

export function Results(props: ResultsProps): JSX.Element {
  let aiqContext = useContext(AIQData);
  let conversationContext = useContext(BorgConversationContext);

  //@ts-ignore

  let [isOpen, setIsOpen] = React.useState(false);
  let [resultsCopied, setResultsCopied] = React.useState(false);
  let results: string | null =
    aiqContext?.data.namespaces?.intro?.results ?? null;
  let splitText = useMemo(() => {
    return results?.split("\n\n");
  }, [results]);
  if (!results) {
    return (
      <div className="text-xl font-bold text-mono">
        Error: No results returned. Our servers may be down. Please try again
        soon! We'll save your answers on your phone so you can try again later.
      </div>
    );
  }

  return (
    <div
      className="relative"
      onClick={() => {
        setIsOpen(true);
      }}
    >
      {!isOpen && (
        <div className="text-mono font-bold bg-black relative top-[10rem]">
          <p className="text-center p-2 w-[100vw] -ml-10 rounded-lg bg-black text-white font-bold font-mono">
            View Results
          </p>
        </div>
      )}
      {resultsCopied ? (
        <p
          onClick={() => {
            navigator.clipboard.writeText(results ?? "Error");
            setResultsCopied(true);
          }}
          className="text-mono mb-0 mt-0 p-2 bg bg-green-200 border-black rounded-lg"
        >
          Saved To Clipboard!
        </p>
      ) : (
        <p
          onClick={() => {
            navigator.clipboard.writeText(results ?? "Error");
            setResultsCopied(true);
          }}
          className="text-mono mb-0 mt-0 p-2 bg bg-yellow-200 border-black rounded-lg transition-opacity duration-1000"
          style={{ opacity: isOpen ? 1 : 0 }}
        >
          We saved your results in your browsers storage. You can tap this box
          to copy your results to your clipboard as well!
        </p>
      )}

      <div
        className="pt-2 text-lg transition-opacity duration-1000"
        style={{ opacity: isOpen ? 1 : 0 }}
      >
        {splitText?.map((text, i) => {
          return (
            <div key={i} className=" pb-4 text-mono font-bold">
              {i === 0 && props.replaceLetterHead ? "Dear Human," : text}
            </div>
          );
        })}
      </div>

      {/* <div className="bg-black w-full h-16 rounded-xl flex justify-center items-center">
        <p
          className=" text-white font-extrabold text-[1.4rem]"
          onClick={() => {
            MatchMakerIntro(conversationContext.transitionScenario);
          }}
        >
          ❤️ Find me Matches ❤️
        </p>
      </div> */}
    </div>
  );
}
