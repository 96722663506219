import React, { useCallback, useContext, useMemo } from "react";
// @ts-ignore
import { AIQData } from "../../pages/AIQ.tsx";
// @ts-ignore
import { BorgConversationContext } from "../BorgChat.tsx";
// @ts-ignore
import { QuestionPack } from "../../scenarios/Persona1.tsx";

export type AddKeyFn = (key: string, value: any) => void;

export type ResultsProps = {
  endpointName: string;
  questions: QuestionPack;
  storageKey: string;
  outputHandler: (
    addKey: AddKeyFn,
    result: string,
    transitionScenario
  ) => boolean;
};

export function ResultsLoader(props: ResultsProps): JSX.Element {
  let aiqContext = useContext(AIQData);
  let conversationContext = useContext(BorgConversationContext);
  let questions = props.questions;

  let [response, setResponse] = React.useState<string | null>(null);
  let splitText = useMemo(() => {
    return response?.split("\n\n");
  }, [response]);

  let request = useMemo(() => {
    let results = Object.entries(questions).map(([key, value], i) => {
      if (aiqContext.data.namespaces.intro === undefined) {
        return `${questions[key].question}\nQuestion not answered`;
      }
      let answer = aiqContext.data.namespaces.intro[key];
      return `${questions[key].question}\n${answer}`;
    });
    fetch(props.endpointName, {
      method: "POST",
      headers: {
        Authorization: "Bearer link_token",
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        survey: results.join("\n\n"),
        paid: aiqContext.data.namespaces.intro.paid ?? false,
      }),
    }).then(async (response) => {
      let json = await response.json();
      // @ts-ignore
      if (!(props.storageKey in aiqContext.data.namespaces.intro)) {
        aiqContext.addKey(props.storageKey, json.response);
        conversationContext.advanceConversation();
      }
    });
  }, []);

  return (
    <div>
      <p>Please do not refresh the page or you'll lose your results!</p>
    </div>
  );
}
